import LanguageSwitcher from "components/LanguageSwitcher/LanguageSwitcher";
import { useSBSState } from "context/global";
import Locale from "translations";

import { Link, useHistory } from "react-router-dom";
import bg from "../../../assets/images/loginLogo.png";
import bgAR from "../../../assets/images/loginlogoAr.png";
import passwordReseted from "../../../assets/images/customRegister/passwordReseted.png";

export default function PasswordChangeSucsses() {
	const { forgetPassword, login } = Locale;
	let history = useHistory();
	const { locale } = useSBSState();

	return (
		<>
			<div className="bg-layout ">
				<div className="d-flex container  justify-content-around">
					<figure className="col-5 pt-3 m-0">
						<img
							src={locale === "en" ? bg : bgAR}
							alt=""
							className="img-fluid"
						/>
					</figure>

					<div className="col-5 d-flex  flex-column  justify-content-between ">
						<div className="d-flex justify-content-end container ">
							<LanguageSwitcher />
						</div>

						<div className="text-center">
							<figure>
								<img className="img-fluid" src={passwordReseted} alt="" />
							</figure>
							<div className="text-center new-fz">
								<p className="m-0">{forgetPassword.passwordChangeSuccess}</p>
							</div>

							<Link to="/auth/login" className="my-3 btn bg-nxt w-75 new-fz">
								{forgetPassword.backLogin}
							</Link>
						</div>
						<div></div>
					</div>
				</div>
			</div>
		</>
	);
}
